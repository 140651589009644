<template>
  <div v-if="walk && user && !isLoading" style="display: flex; width: 100%;" :style="!$vuetify.breakpoint.mdAndUp ? 'padding-bottom: 84px' : ''">
    <div style="flex: 1;">
      <v-toolbar
        color="#ffffff"
        style="left: 0; border-bottom: 1px solid #1b7476;"
        width="100%">
        <walk-progress-bar v-model="currentTab" :walk="walk" />
        <v-btn
          v-if="$vuetify.breakpoint.mdAndUp"
          @click="isSchoolResourcesDialogVisible = true"
          class="ml-2"
          color="#C58C99"
          icon
        >
            <v-icon>mdi-help-box-multiple-outline</v-icon>
          </v-btn>
        <v-app-bar-nav-icon v-if="$vuetify.breakpoint.mdAndUp" @click.stop="isObservationsDrawerVisible = !isObservationsDrawerVisible" ></v-app-bar-nav-icon>
      </v-toolbar>
      <div class="px-3 py-2"  :style="$vuetify.breakpoint.mdAndUp ? 'overflow-y: auto; height: calc(100vh - 64px - 64px)' : ''">
        <div v-if="-1 === currentTab">
          <walk-header :walk="walk"></walk-header>
          <walk-history-card
            v-if="walk.submissionHistory && walk.submissionHistory.length"
            :walk="walk"
            class="mt-1 mb-2"></walk-history-card>

          <div class="row justify-center">
            <v-btn
              @click="currentTab = 1"
              color="primary"
              large
              outlined
            >
              Continue
            </v-btn>
          </div>
        </div>
        <div v-if="0 === currentTab">
          <div class="row justify-center pa-2 pt-0">
            <div
              v-for="observationCategory in observationCategories"
              :key="observationCategory.id"
              class="col-6"
              style="padding: 8px"
            >
              <v-card
                @click="openCreateObservationDialogForCategory(observationCategory.id)"
                class="ma-0 pa-0"
                style="height: 100%; width: 100%; display: flex; flex-direction: column;"
              >
                <div class="text-subtitle-2 text-center pa-1">{{ observationCategory.label }}</div>
                <v-spacer></v-spacer>
                <div class="row justify-center mb-1" style="align-items: end;">
                  <v-img
                    :src="require(`@/assets/images/${getImagePathForCategory(observationCategory)}.png`)"
                    color="transparent"
                    contain
                    style="border: 2px solid gray; border-radius: 1000px; max-height: 80px !important; max-width: 80px !important;"></v-img>
                </div>
              </v-card>
            </div>
            <div
              class="col-6"
              style="padding: 8px"
            >
              <v-card
                @click="openCreateObservationDialogForCategory(null)"
                class="ma-0 pa-0"
                style="height: 100%; width: 100%; display: flex; flex-direction: column;"
              >
                <div class="text-subtitle-2 text-center pa-1">Master List</div>
                <div class="row justify-center mb-1" style="align-items: end;">
                  <v-img
                    :src="require(`@/assets/images/a_to_z.png`)"
                    color="transparent"
                    contain
                    style="border: 2px solid gray; border-radius: 1000px; max-height: 80px !important; max-width: 80px !important;"></v-img>
                </div>
                <v-spacer></v-spacer>
                <div class="row justify-center mb-1" style="align-items: end;">
                </div>
              </v-card>
            </div>
          </div>
          <div class="row justify-center">
            <v-btn
              @click="currentTab = 1"
              color="primary"
              large
              outlined
            >
              Continue
            </v-btn>
          </div>
        </div>

        <div v-if="1 === currentTab">
          <div class="text-subtitle-1 text--secondary">
            Before submitting, take a moment to review how SchoolDog can help you turn your data into safer schools.
          </div>
          <v-card class="mt-1 mb-2">
            <v-card-title>
              Download Reports
            </v-card-title>
            <v-card-subtitle>
              Let SchoolDog automatically generate reports for you, ranging from simple data reporting to advanced analysis.
            </v-card-subtitle>
            <v-card-text>
              <div>
                <v-btn @click="generateReport('observations')" color="primary" text>
                  <v-icon>mdi-file-pdf-box</v-icon><span style="padding: 12px">Observations Report</span>
                </v-btn>
              </div>
              <div>
                <v-btn disabled text>
                  <v-icon>mdi-lock</v-icon><v-icon>mdi-file-pdf-box</v-icon><span style="padding: 12px">Full PDF Summative Report</span>
                </v-btn>
              </div>
              <div>
                <v-btn disabled text>
                  <v-icon>mdi-lock</v-icon><v-icon>mdi-file-excel-box</v-icon><span style="padding: 12px">Filtered Observation Data</span>
                </v-btn>
              </div>
            </v-card-text>
          </v-card>
          <v-card class="mt-1 mb-2">
            <v-card-title>
              Assign Tasks
            </v-card-title>
            <v-card-subtitle>
              SchoolDog allows your leaders to send notifications and photos easily to key personnel in the form of tasks so that safety issues don't just get logged as data entries, but assigned to the right person for action!
            </v-card-subtitle>
          </v-card>
          <v-card class="mt-1 mb-2">
            <v-card-title>
              Use The Data Insights Tool
            </v-card-title>
            <v-card-subtitle>
              SchoolDog's Data Insights tool allows you to quickly visualize trends, search across all of your data to discover patterns and areas to address, and ultimately help make data-driven decisions.
            </v-card-subtitle>
          </v-card>
          <v-card class="mt-1 mb-2">
            <v-card-title>
              Inspire Action
            </v-card-title>
            <v-card-subtitle>
              SchoolDog automatically facilitates monthly activity reports, "Neighborhood Watch" alerts, live-updating leaderboards, and much more. All of SchoolDog's communication features are designed to ignite known human tendencies in leaders to stay vigilant and spark proactive leadership.
            </v-card-subtitle>
          </v-card>
          <div class="d-flex justify-center">
            <v-btn
              @click="downloadFlyer()"
              color="primary"
              class="mb-1"
              large
              text>
              Learn more about what SchoolDog can do
            </v-btn>
          </div>
          <div class="row justify-center">
            <v-btn
              v-if="!walk.isSubmitted"
              @click="submitWalk()"
              color="primary"
              large
              outlined
            >
              Submit Data
            </v-btn>
            <div v-else class="text-subtitle-1 text--secondary mx-6 text-center my-2">
              <div><v-icon color="success">mdi-check</v-icon></div>
              <div>Your data has been submitted. You may continue to add data and explore the tool throughout the conference. Thank you for using SchoolDog!</div>
            </div>
          </div>
          <div class="row justify-center">
            <v-btn
              @click="currentTab = 0"
              color="grey darken-1"
              large
              text
            >
              Back
            </v-btn>
          </div>
        </div>
      </div>
    </div>

    <v-navigation-drawer
      v-if="$vuetify.breakpoint.mdAndUp"
      v-model="isObservationsDrawerVisible"
      class="elevation-1"
      floating
      permanent
      right
      width="360px"
      :style="!(isObservationsDrawerVisible && $vuetify.breakpoint.mdAndUp) ? 'width: 0' : ''"
    >
      <div>
        <div>
          <div style="display: flex; justify-content: center; align-items: center;">
            <div style="flex: 1">
              <v-card-title>
                Observation entries
              </v-card-title>
              <v-card-subtitle>
                {{ numberOfConcerns }} concern{{numberOfConcerns === 1 ? '' : 's'}}, {{ numberOfPraises}} praise{{numberOfPraises === 1 ? '' : 's'}}, {{ numberOfOther }} other
              </v-card-subtitle>
            </div>
            <div class="pa-1">
              <v-btn
                @click="openCreateObservationDialogForCategory(null)"
                color="primary"
                outlined>
                Add
              </v-btn>
            </div>
          </div>
        </div>
        <v-divider color="primary"></v-divider>
        <div
          v-if="walkObservations.length"
          class="px-2 pt-2"
          style="overflow-y: scroll; height: calc(100vh - 64px - 84px)">
          <observation-card
            v-for="(observation, index) in walkObservations"
            @delete="deleteObservation(observation)"
            @edit="editObservation(observation.id)"
            :key="`${index}-observation`"
            :observation="observation"
            :observation-number="index + 1"
            :readonly="!(isUserLeader || observation.reportedByUserId === user.id)"
            :settings="observationSettings"
            class="mb-2">
          </observation-card>
        </div>
        <div
          v-else
          class="text-subtitle-1 grey--text text--darken-1"
          style="overflow-y: scroll; height: calc(100vh - 64px - 84px); display: flex; justify-content: center; align-items: center">
          No Observations Yet
        </div>
      </div>
    </v-navigation-drawer>

    <v-fab-transition v-if="!$vuetify.breakpoint.mdAndUp && walkObservations.length">
      <v-btn
        @click="isObservationsDialogVisible = true"
        fixed
        color="grey darken-1"
        dark
        elevation="12"
        bottom
        left
        rounded
        x-large
        style="left: 100px"
      >
        {{ walkObservations.length }} Entr{{
          walkObservations.length === 1 ? "y" : "ies"
        }}
      </v-btn>
    </v-fab-transition>

    <v-fab-transition v-if="!$vuetify.breakpoint.mdAndUp">
      <v-btn
        @click="isSchoolResourcesDialogVisible = true"
        bottom
        color="#C58C99"
        elevation="12"
        fab
        fixed
        left
      >
        <v-icon color="white">mdi-help-box-multiple-outline</v-icon>
      </v-btn>
    </v-fab-transition>

    <v-fab-transition v-if="!$vuetify.breakpoint.mdAndUp">
      <v-btn
        @click="openCreateObservationDialogForCategory(null)"
        bottom
        color="primary"
        elevation="12"
        fab
        fixed
        right
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </v-fab-transition>

    <!-- DIALOGS -->
    <create-observation-dialog
      v-model="isCreateObservationDialogVisible"
      @save="saveNewObservation"
      :initial-observation-category-id="newObservationCategoryId"
      :school-id="walk.schoolId"
      :walk-id="walk.id" />

    <create-observation-dialog
      v-model="isEditObservationDialogVisible"
      @save="saveEditObservationChanges($event)"
      :initial-observation="editingObservation"
      :school-id="walk.schoolId"
      :walk-id="walk.id"
      is-editing />

    <v-dialog
      v-model="isSettingsDialogVisible"
      max-width="600px">
      <v-card>
        <div style="display: flex; justify-content: space-between; align-items: center">
          <div>
            <v-card-title>
              SchoolDog Walk Settings
            </v-card-title>
          </div>
          <v-btn @click="isSettingsDialogVisible = false" class="mx-1" icon>
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </div>
        <v-card-text>
          <v-card class="mb-2">
            <v-card-text>
              <div class="text-subtitle-1 mb-2 d-flex justify-space-between align-center" style="width: 100%">
                <div>Access info</div>
                <v-btn v-if="user && (walk.leaderUserId === user.id)" text @click="isManageWalkAccessDialogVisible = true" color="primary">Manage access</v-btn>
              </div>
              <div>
                This SchoolDog Walk is being led by <user-link :userId="walk.leaderUserId" />.
              </div>
              <div v-if="walk.collaborators && walk.collaborators.length" class="mt-2">
                <div>
                  Additionally, there {{ walk.collaborators.length === 1 ? 'is' : 'are'}} {{ walk.collaborators.length }} collaborator{{ walk.collaborators.length === 1 ? '' : 's' }}:
                </div>
                <ul>
                  <li v-for="collaborator in walk.collaborators" :key="collaborator">
                    <user-link :userId="collaborator" />
                  </li>
                </ul>
              </div>
              <manage-walk-access-dialog
                v-model="isManageWalkAccessDialogVisible"
                :walk="walk" />
            </v-card-text>
          </v-card>
          <v-card class="mb-2 mt-1">
            <v-card-text>
              <div class="text-subtitle-1 mb-2 d-flex justify-space-between align-center" style="width: 100%">
                <div>School info</div>
                <v-btn text @click="isSchoolResourcesDialogVisible = true" color="#C58C99">View resources</v-btn>
              </div>
              <div class="text-body-2 mb-1">
                <div class="font-weight-thin text-caption">
                  School Name
                </div>
                <div class="font-weight-medium">
                  {{ school.name }}
                </div>
              </div>
            </v-card-text>
          </v-card>
          <div class="d-flex justify-center">
            <v-btn
              @click="isDeleteWalkDialogVisible = true"
              color="error"
              text>
              Permanently delete SchoolDog Walk
            </v-btn>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            @click="isSettingsDialogVisible = false"
            color="grey darken-1"
            text>
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="isObservationsDialogVisible"
      v-if="isObservationsDialogVisible"
    >
      <v-card>
        <div>
          <div style="display: flex; justify-content: space-between; align-items: center">
            <div>
              <v-card-title>
                Observation entries
              </v-card-title>
              <v-card-subtitle>
                {{ numberOfConcerns }} concern{{numberOfConcerns === 1 ? '' : 's'}}, {{ numberOfPraises}} praise{{numberOfPraises === 1 ? '' : 's'}}, {{ numberOfOther }} other
              </v-card-subtitle>
            </div>
            <v-btn @click="isObservationsDialogVisible = false" class="mx-1" icon>
              <v-icon>
                mdi-close
              </v-icon>
            </v-btn>
          </div>
          <v-divider></v-divider>
          <div
            v-if="walkObservations.length"
            class="px-2 py-2">
            <observation-card
              v-for="(observation, index) in walkObservations"
              @delete="deleteObservation(observation)"
              @edit="editObservation(observation.id)"
              :key="`${index}-observation`"
              :observation="observation"
              :observation-number="index + 1"
              :readonly="!(isUserLeader || observation.reportedByUserId === user.id)"
              :settings="observationSettings"
              class="mb-2">
            </observation-card>
          </div>
          <div
            class="text-subtitle-1 grey--text text--darken-1 pa-2"
            v-else>
            No Entries Yet
          </div>
        </div>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            @click="isObservationsDialogVisible = false"
            color="grey darken-1"
            text>
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="isSentForReviewDialogVisible" max-width="528px">
      <v-card v-if="isSentForReviewDialogVisible">
        <v-card-title>
          Revisions Requested
        </v-card-title>
        <v-card-text>
          <div>
            This SchoolDog Walk was reviewed by <span v-if="reviewedBy">{{reviewedBy.firstName}} {{reviewedBy.lastName}}</span><span v-else>Unknown</span>, and revisions were requested for your consideration. Please review the comments below, determine whether to make revisions to the SchoolDog Walk, and then complete and finalize the report.
          </div>
          <div class="mt-2">
            <b>Review Comments</b>
            <div class="text-body-1">
              "{{ walk.submissionHistory[walk.submissionHistory.length - 1].comments }}"
            </div>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="isSentForReviewDialogVisible = false" color="primary">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="isReviewingSubmissionDialogVisible" max-width="528px">
      <v-card>
        <v-progress-linear
          v-if="isSubmitting"
          indeterminate
          color="primary" />
        <v-form v-model="isSubmissionReviewFormValid" ref="submissionReviewForm">
          <v-card-title>
            Review and Complete
          </v-card-title>
          <v-card-text>
            <div class="d-flex">
              <div>
                You are about to complete a SchoolDog Walk containing {{ numberOfConcerns }} concern{{numberOfConcerns === 1 ? '' : 's'}}, {{ numberOfPraises}} praise{{numberOfPraises === 1 ? '' : 's'}}, and {{ numberOfOther }} other entries. <span v-if="!walkObservations.length">It is recommended to record at least one entry before completing.</span><span v-else>If you have more entries to record, please do so before completing.</span>
              </div>
            </div>
            <v-checkbox
              v-if="!walk.hasSentNeighborhoodWatch && user.can('send neighborhood watch')"
              v-model="walk.shouldSendNeighborhoodWatch"
              @change="saveWalk()">
              <template v-slot:label>
                <div>
                  Send <glossary-term text='"Neighborhood Watch"' /> email after completion
                </div>
              </template>
            </v-checkbox>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              @click="isReviewingSubmissionDialogVisible = false"
              color="grey darken-1"
              text>
              Cancel
            </v-btn>
            <v-btn
              @click="doSubmissionAction()"
              :disabled="isSubmitting"
              :loading="isSubmitting"
              color="primary">
              Complete SchoolDog Walk
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <v-dialog v-if="school" v-model="isSchoolResourcesDialogVisible" max-width="640px">
      <v-card>
        <div style="display: flex; justify-content: space-between; align-items: center">
          <v-card-title>
            Resources
          </v-card-title>
          <v-btn @click="isSchoolResourcesDialogVisible = false" class="mx-1" icon>
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </div>
        <v-card-text>
          <div class="text-h5 mb-1">
            {{ conferenceInfo.name }}
          </div>
          <div class="text-body-1 mb-2">
            Try recording five SchoolDog entries during the conference using your device (make sure to include a photo in each entry).
          </div>
          <div class="text-h5 my-1">
            Resource Links
          </div>
          <resource-link-cards></resource-link-cards>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="isInstructionsDialogVisible" max-width="640px">
      <v-card>
        <v-card-title>
          Welcome to SchoolDog!
        </v-card-title>
        <v-card-text>
          <div class="mb-1">
            Use SchoolDog and take a “conference walk” this week.
          </div>
          <div class="mb-1">
            Try recording five SchoolDog entries during the conference using your device (make sure to include a photo in each entry).
          </div>
          <div class="mb-1">
            Use your safety eyes and noses with SchoolDog (and expertise), and “Amplify the Human Element!”
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="mb-1"
            @click="isInstructionsDialogVisible = false"
            color="primary">
            Get Started
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
     <v-dialog v-model="isGeneratingReport" persistent max-width="528px">
      <v-card>
        <v-card-title>
          Generating Report...
        </v-card-title>
        <v-card-text>
          <div>
            This might take a few seconds.
          </div>
          <div class="row justify-center pt-4">
            <v-progress-circular color="primary" indeterminate></v-progress-circular>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="isDownloadedReportDialogVisible" max-width="528px">
      <v-card>
        <v-card-title>
          Your Report Is Ready
        </v-card-title>
        <v-card-text>
          <div>
            Your generated report should have opened automatically. You can also use the link below to download. The link is only valid for 10 minutes after generation.
          </div>
          <div class="row justify-center pt-4">
            <v-btn text :href="reportUrl" target="_blank" color="primary">
              <v-icon>mdi-download</v-icon><span style="padding: 12px">Download Generated Report</span>
            </v-btn>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="isDownloadedReportDialogVisible = false" color="primary">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
// import uuidv4
import { v4 as uuidv4 } from 'uuid';
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'

import Walk from '@/models/Walk'
import SchoolLocations from '@/models/SchoolLocations'
import ObservationCard from '../../components/walks/ObservationCard.vue';
import WalkHistoryCard from '../../components/walks/WalkHistoryCard.vue';
import ResourceLinkCards from '../../components/resources/ResourceLinkCards.vue';
import CreateObservationDialog from '../../components/walks/CreateObservationDialog.vue';
import ManageWalkAccessDialog from '../../components/walks/ManageWalkAccessDialog.vue';
import WalkProgressBar from '../../components/walks/WalkProgressBar.vue';
import GlossaryTerm from '../../components/common/GlossaryTerm.vue';

export default {
  components: {
    ObservationCard,
    WalkHistoryCard,
    ResourceLinkCards,
    ManageWalkAccessDialog,
    CreateObservationDialog,
    WalkProgressBar,
    GlossaryTerm,
  },
  name: 'EditWalk',
  data() {
    return {
      currentTab: 0, 
      editingObservation: null,
      editingObservationId: null,
      isCreateObservationDialogVisible: false,
      isDeleteWalkDialogVisible: false,
      isEditObservationDialogVisible: false,
      isEditObservationFormValid: false,
      isObservationsDialogVisible: false,
      isObservationsDrawerVisible: true,
      isLoadingWalk: false,
      isSettingsDialogVisible: false,
      isManageWalkAccessDialogVisible: false,
      isReviewingSubmissionDialogVisible: false,
      isSchoolResourcesDialogVisible: false,
      isSentForReviewDialogVisible: false,
      isSubmissionReviewFormValid: false,
      isSubmittingObservation: false,
      isSubmitting: false,
      isUsingTileView: true,
      newObservationCategoryId: null,
      objectRule: value => (Boolean(value) && typeof value === 'object' && Boolean(value.value)) || 'Please choose from provided options',
      requiredRule: value => Boolean(value) || 'This field is required',
      schoolLocations: null,
      submissionReviewSendToSafetyTeam: true,
      submissionReviewerId: null,
      walk: null,
      isInstructionsDialogVisible: false,
      isDownloadedReportDialogVisible: false,
      isGeneratingReport: false,
      reportUrl: null,
    };
  },
  computed: {
    ...mapGetters('app', [
      'schools',
      'getEligibleReviewersForWalk',
      'getSchoolById',
      'conferenceInfo',
    ]),
    ...mapState('app', [
      'isOffline',
      'observationSettings',
      'organizationSettings',
      'user',
      'users',
      'walksById',
    ]),
    isUserLeader () {
      return this.user && this.user.id === this.walk.leaderUserId
    },
    numberOfConcerns () {
      return this.walkObservations.filter(observation => 'concern' === observation.observationType).length
    },
    numberOfOther () {
      return this.walkObservations.filter(observation => 'other' === observation.observationType).length
    },
    numberOfPraises () {
      return this.walkObservations.filter(observation => 'praise' === observation.observationType).length
    },
    observationCategoryById() {
      const map = {};
      this.observationCategories.forEach((observationCategory) => {
        map[observationCategory.id] = observationCategory;
      });
      return map;
    },
    observationCategories() {
      if (this.observationSettings) {
        return this.observationSettings.categories;
      }
      return [];
    },
    observationOptionsByCategoryId () {
      const map = {}
      this.observationCategories.forEach((category) => {
        map[category.id] = this.observationItemsByCategory[category.id].map((observationItem) => ({ text: observationItem.label, value: observationItem.id })).sort((a, b) => {
          if (a.text === 'Other' && b.text === 'Other') return 0;
          if (a.text === 'Other') return 1;
          if (b.text === 'Other') return -1;
          return a.text.localeCompare(b.text);
        })
      })
      return map
    },
    observationOptionsWithCategories () {
      const options = []
      this.observationItems.forEach((observationItem) => {
        observationItem.categories.forEach((categoryId) => {
          options.push({
            text: `${observationItem.label} (${this.observationCategoryById[categoryId].label})`,
            value: `${categoryId}__${observationItem.id}`,
          })
        })
      })
      return options.sort((a, b) => {
        if (a.text === 'Other' && b.text === 'Other') return 0;
        if (a.text === 'Other') return 1;
        if (b.text === 'Other') return -1;
        return a.text.localeCompare(b.text);
      });
    },
    observationItems() {
      if (this.observationSettings) {
        return this.observationSettings.items;
      }
      return [];
    },
    observationItemById() {
      const map = {};
      this.observationItems.forEach((observationItem) => {
        map[observationItem.id] = observationItem;
      });
      return map;
    },
    observationItemsByCategory() {
      const map = {};
      if (!this.observationSettings) {
        return map
      }
      this.observationSettings.items.forEach((observationItem) => {
        observationItem.categories.forEach((categoryId) => {
          if (!map[categoryId]) {
            map[categoryId] = []
          }
          map[categoryId].push(observationItem)
        })
      })
      return map;
    },
    reviewedBy() {
      const matchingUsers = this.users.filter((user) => user.id == this.walk.awaitingReviewBy)
      if (matchingUsers.length > 0) {
        return matchingUsers[0];
      }
      return null;
    },
    isLoading() {
      return (
        this.isLoadingWalk
      );
    },
    school () {
      return this.getSchoolById(this.walk.schoolId) || {}
    },
    walkObservations () {
      const observations = Object.values(this.walk.observationsById || {}).filter(observation => !observation.isDeleted)
      observations.sort((a, b) => {
        return new Date(a.timestamp) - new Date(b.timestamp)
      })
      return observations
    },
  },
  methods: {
    ...mapActions('app', [
      'syncWalks',
      'showError',
      'showSuccess',
    ]),
    async submitWalk() {
      this.walk.isSubmitted = true;
      await this.walk.save();
    },
    ...mapMutations('app', [
      'removeWalk',
    ]),
    async completeWalk() {
      this.isSubmitting = true;
      try {
        await this.runFunction('completeWalk', {
          walkId: this.walk.id,
        })
      } finally {
        this.isSubmitting = false
      }
    },
    async deleteObservation(observation) {
      observation.isDeleted = true
      this.walk.save()
    },
    async generateReport(reportType) {
      this.isGeneratingReport = true;

      try {
        const data = await this.runFunction('generateReport', {
          reportType,
          walkId: this.walk.id,
        })
        this.reportUrl = data
        window.open(data, '_blank');

        this.isDownloadedReportDialogVisible = true
      } finally {
        this.isGeneratingReport = false
      }
    },
    async doSubmissionAction() {
      await this.completeWalk();
    },
    editObservation(observationId) {
      this.editingObservationId = observationId
      this.editingObservation = JSON.parse(JSON.stringify(this.walk.observationsById[observationId]))
      this.isEditObservationDialogVisible = true
    },
    getImagePathForCategory(category) {
      return category.image;
    },
    async deleteWalk() {
      try {
        this.isSubmitting = true;
        await this.runFunction('archiveWalk', {
          walkId: this.walk.id,
        })
        this.showSuccess('Successfully deleted SchoolDog Walk');
        this.removeWalk(this.walk)
        this.$router.replace('/walks');
      } catch {
        this.showError('Failed to delete SchoolDog Walk');
      } finally {
        this.isSubmitting = false;
      }
    },
    async loadWalk() {
      this.isLoadingWalk = true;

      await this.syncWalks()
      try {
        this.walk = await Walk.getById(this.$route.params.walkId)
        if (!this.walk) {
          throw new Error('Walk does not exist');
        }
      } catch (e) {
        console.error(this.$route.params.walkId, e)
        this.$router.replace('/walks');
        return
      }

      this.isLoadingWalk = false;

      this.schoolLocations = await SchoolLocations.getById(this.walk.schoolId)
    },
    openCreateObservationDialogForCategory(observationCategoryId) {
      this.newObservationCategoryId = observationCategoryId;
      this.isCreateObservationDialogVisible = true;
    },
    async saveEditObservationChanges(observation) {
      Object.keys(observation).forEach((key) => {
        this.walk.observationsById[this.editingObservationId][key] = observation[key]
      })
      this.saveWalk('saveedit');
    },
    async saveNewObservation(newObservation) {
      const observation = {
        ...newObservation,
        timestamp: new Date(),
        reportedByUserId: this.user.id,
        id: uuidv4(),
      };
      if (!this.walk.observationsById) {
        this.walk.observationsById = {};
      }
      this.$set(this.walk.observationsById, observation.id, observation);
      this.walk.save();
      this.$forceUpdate();

      // Don't bother caching common locations while offline, inefficient use of resources
      if (!this.isOffline && observation.location) {
        if (!this.schoolLocations.usageMap[observation.location]) {
          this.$set(this.schoolLocations.usageMap, observation.location, 0);
        }
        this.$set(this.schoolLocations.usageMap, observation.location, this.schoolLocations.usageMap[observation.location] + 1);
        this.schoolLocations.save();
      }
    },
    async saveWalk() {
      this.walk.save()
    },
    setObservationItemId (observation, observationItem) {
      const observationItemId = observationItem.value
      if (observationItemId && observationItemId.includes('__')) {
        const parts = observationItemId.split('__');
        observation.observationCategoryId = parts[0]
        observation.observationItemId = parts[1]
        this.$forceUpdate()
      } else {
        observation.observationItemId = observationItemId
        this.$forceUpdate();
      }
    },
    downloadFlyer() {
      // I have a flyer in /assets/images/conf_flyer.jpeg
      const link = document.createElement('a');
      link.href = require('@/assets/images/conf_flyer.jpeg'); // Adjust the path as necessary
      link.download = 'SchoolDog Flyer.jpeg';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    scrollToSubmit() {
      this.$refs.submitBtn.$el.scrollIntoView({ behavior: 'smooth' });
    },
  },
  async mounted() {
    await this.loadWalk();
    this.isInstructionsDialogVisible = true;
    if (this.walk) {
      this.walk.startListening()
    }
  },
  destroyed () {
    if (this.walk) {
      this.walk.stopListening()
    }
  },
  watch: {
    currentTab () {
      if (0 === this.currentTab && this.walk.currentStage !== 'observations') {
        this.walk.currentStage = 'observations';
      } else if (1 === this.currentTab && this.walk.currentStage !== 'review') {
        this.walk.currentStage = 'review';
      } else if (this.currentTab < 0 || this.currentTab > 2) {
        this.currentTab = 0;
        return;
      }
      localStorage.setItem(`walk-${this.$route.params.walkId}-tab`, this.currentTab)
    },
    'walk.status' () {
      if (this.walk && 'complete' === this.walk.status) {
        this.$router.replace(`/walks/walk/${this.walk.id}/complete`);
        this.showSuccess('SchoolDog Walk Completed');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .v-navigation-drawer__content {
  padding: 0;
}

</style>
