<template>
<div v-if="user">
  <v-menu offset-y left transition="slide-y-transition"  class="pa-1">
    <template v-slot:activator="{ on }">
      <v-btn icon class="elevation-2" v-on="on" style="width: 36px; height: 36px">
        <user-avatar
          prevent-click
          :user-id="user.id"
          size="36"></user-avatar>
      </v-btn>
    </template>

    <!-- user menu list -->
    <v-list dense nav>
      <v-list-item @click="doLogOut()">
        <v-list-item-icon>
          <v-icon small>mdi-logout-variant</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ $t('menu.logout') }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
  <edit-user-dialog
    v-model="isEditUserDialogVisible"
    @refresh="refreshUser()"
    :userToEdit="user"></edit-user-dialog>
</div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import UserAvatar from '../common/UserAvatar.vue';
import EditUserDialog from './../manageUsers/EditUserDialog.vue'
/*
|---------------------------------------------------------------------
| Toolbar User Component
|---------------------------------------------------------------------
|
| Quickmenu for user menu shortcuts on the toolbar
|
*/
export default {
  name: 'ToolbarUser',
  components: {
    EditUserDialog,
    UserAvatar,
  },
  data() {
    return {
      isEditUserDialogVisible: false,
    }
  },
  computed: {
    ...mapState('app', [
      'user',
    ]),
  },
  methods: {
    ...mapActions({
      setUserFromFirebaseUser: 'app/setUserFromFirebaseUser',
      logOut: 'app/logOut',
    }),
    refreshUser () {
      this.setUserFromFirebaseUser()
      this.isEditUserDialogVisible = false
    },
    async doLogOut() {
      await this.logOut()
    },
  },
}
</script>
