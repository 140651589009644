export const appConfig =
{
  apiKey: 'AIzaSyBkwo8sBF0aPFsJj0DKk51aJcm2wOOwcZI',
  authDomain: 'schooldog-i-conference.firebaseapp.com',
  projectId: 'schooldog-i-conference',
  storageBucket: 'schooldog-i-conference.appspot.com',
  messagingSenderId: '104929068027',
  appId: '1:104929068027:web:e3f0a2f3bea2b12af4015e',
  measurementId: 'G-HRSS6WZRT3',
  showGaBranding: true,
}
