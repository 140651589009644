<template>
  <v-dialog
    v-if="value"
    @input="$emit('input', false)"
    :value="value"
    max-width="528px"
  >
    <v-card>
      <v-progress-linear
        v-if="isSubmitting"
        indeterminate
      ></v-progress-linear>
      <v-card-title> {{ isEditing ? 'Edit' : 'Record new'}} observation entry </v-card-title>
      <v-card-text>
        <v-form v-model="isObservationFormValid" ref="observationForm">
          <v-card>
            <v-card-subtitle class="pb-0">
              <div class="text-subtitle-1">Details</div>
            </v-card-subtitle>
            <v-container>
              <observation-category-select
                v-model="editingObservation.observationCategoryId"
                :rules="[requiredRule]"
                @input="
                  editingObservation.observationItemId = null;
                  $forceUpdate();
                "
              ></observation-category-select>
              <observation-item-select
                v-model="editingObservation.observationItemId"
                class="mt-1 wrap-text-dropdown"
                :name="isEditing ? 'editObservation' : 'newObservation'"
                :observation-category-id.sync="editingObservation.observationCategoryId"
                :rules="[requiredRule, objectRule]"
                validate-on-blur
              ></observation-item-select>
              <div class="mt-1 text-caption text--secondary">
                NOTE: The categories and observations listed above are a limited sample of items available in the SchoolDog platform for demonstration purposes.
              </div>
              <v-radio-group
                v-model="editingObservation.observationType"
                class="mt-1 mb-2"
                dense
                hide-details
                :rules="[requiredRule]"
              >
                <template v-slot:label>
                  <div><b>Observation Type</b></div>
                </template>
                <v-radio label="Concern" value="concern"></v-radio>
                <v-radio label="Praise" value="praise"></v-radio>
                <v-radio label="Other" value="other"></v-radio>
              </v-radio-group>
              <observation-location-select
                v-model="editingObservation.location"
                ref="locationCombobx"
                :school-id="schoolId"
                :name="isEditing ? 'editLocation' : 'newLocation'"
                header-text="Select an existing location or type a new one."
                class="mt-1"
                clearable
              ></observation-location-select>
              <v-textarea
                v-model="editingObservation.comment"
                :rows="3"
                auto-grow
                class="mt-1"
                hide-details="auto"
                hint="Describe your observation in sufficient detail that it can be addressed"
                label="Description"
                outlined
                dense
              ></v-textarea>
              <compressed-image-input
                v-model="editingObservation.pictureFiles"
                multiple
              ></compressed-image-input>
              <v-divider v-if="editingObservation.imagePaths && editingObservation.imagePaths.length" class="mb-2" />
              <div
                v-if="
                  editingObservation.imagePaths &&
                  editingObservation.imagePaths.length
                "
                style="
                  display: flex;
                  justify-content: space-between;
                  flex-wrap: wrap;n
                "
              >
                <div
                  v-for="(
                    imagePath, imagePathIndex
                  ) in editingObservation.imagePaths"
                  :key="imagePath"
                  class="ma-1 d-flex flex-column space-between"
                  style="width: calc(50% - 16px); max-width: calc(50% - 16px);"
                >
                  <v-img v-if="imageUrls[imagePathIndex]" :src="imageUrls[imagePathIndex]"></v-img>
                  <v-skeleton-loader v-else type="image" style="width: 100px"></v-skeleton-loader>
                  <div class="flex-grow-1"></div>
                  <div style="display: flex; justify-content: center;">
                    <v-btn
                      @click="
                        editingObservation.imagePaths.splice(
                          imagePathIndex,
                          1
                        );
                        setImageUrls();
                      "
                      icon
                    >
                      <v-icon> mdi-delete </v-icon>
                    </v-btn>
                  </div>
                </div>
              </div>
            </v-container>
          </v-card>
          <v-card
            v-if="user.can('create tasks from observations') && !editingObservation.taskId"
            class="mt-2"
          >
            <v-card-subtitle class="pb-0">
              <div class="text-subtitle-1">Take action</div>
            </v-card-subtitle>
            <v-container>
              <v-checkbox
                v-model="editingObservation.shouldAssignAsTask"
                label="Create a task based on this entry"
                hide-details
                color="primary"
                class="ma-0"
              ></v-checkbox>
              <div
                v-if="editingObservation.shouldAssignAsTask"
                class="text-caption mt-1"
              >
                NOTE: This task will not be created until the SchoolDog Walk is
                completed.
              </div>

              <users-select
                v-if="editingObservation.shouldAssignAsTask"
                v-model="editingObservation.taskAssignUserId"
                :rules="[requiredRule]"
                dense
                label="User to propose Task to"
                persistent-hint
                show-self
                hide-details="auto"
                :valid-user-ids="getEligibleTaskAssigneesForTask({ schoolId: schoolId}).map(user => user.value)"
                outlined
                class="mt-2" />
              <v-text-field
                v-if="editingObservation.shouldAssignAsTask"
                @input="editingObservation.taskTitle = $event"
                :value="
                  editingObservation.taskTitle ||
                  'Follow up on this observation entry'
                "
                class="mt-1"
                outlined
                dense
                label="Task Title"
                hide-details="auto"
              ></v-text-field>
              <v-textarea
                v-if="editingObservation.shouldAssignAsTask"
                @input="editingObservation.taskDescription = $event"
                :value="
                  editingObservation.taskDescription ||
                  'See the observation entry details for more information.'
                "
                class="mt-1"
                label="Task Description"
                hint="The Observation Details will automatically be included with the task."
                hide-details="auto"
                outlined
                dense
              ></v-textarea>
            </v-container>
          </v-card>
        </v-form>
        <div class="mt-2">
          <div v-if="preview">
            <b>Summary</b>
          </div>
          <div
            v-if="preview"
            class="text-center"
          >
            {{ preview }}
          </div>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="close()" color="grey darken-1" text>
          Cancel
        </v-btn>
        <v-btn
          @click="save()"
          :disabled="isSubmitting"
          color="primary"
        >
          {{ isEditing ? 'Save Changes' : 'Add' }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import uuid from 'uuid'
import { mapGetters, mapState } from 'vuex';
import {
  getStorage,
  ref as storageRef,
  uploadBytesResumable,
  getDownloadURL,
  ref,
} from 'firebase/storage';

import CompressedImageInput from '@/components/common/CompressedImageInput.vue';
import ObservationCategorySelect from '@/components/walks/ObservationCategorySelect.vue';
import ObservationItemSelect from '@/components/walks/ObservationItemSelect.vue';
import ObservationLocationSelect from '@/components/walks/ObservationLocationSelect.vue';
import ImageUploadService from '@/services/ImageUploadService';
import UsersSelect from '../common/UsersSelect.vue';

export default {
  name: 'CreateObservationDialog',
  components: {
    CompressedImageInput,
    ObservationCategorySelect,
    ObservationLocationSelect,
    ObservationItemSelect,
    UsersSelect,
  },
  props: {
    isEditing: {
      type: Boolean,
      default: false,
    },
    value: Boolean,
    initialObservationCategoryId: String,
    initialObservation: {
      type: Object,
      default: () => ({
        comment: null,
        location: null,
        observationCategoryId: null,
        observationItemId: null,
        observationType: null,
        pictureFiles: [],
        imagePaths: [],
      }),
    },
    schoolId: String,
    walkId: String, // only needed for file upload
  },
  data() {
    return {
      editingObservation: null,
      imageUrls: [],
      isObservationFormValid: false,
      isSubmitting: false,
      isUsingTileView: true,
      objectRule: (value) =>
        (Boolean(value) && typeof value === 'object' && Boolean(value.value)) ||
        'Please choose from provided options',
      requiredRule: (value) => Boolean(value) || 'This field is required',
      schoolLocations: null,
    };
  },
  computed: {
    ...mapGetters('app', [
      'schools',
      'getEligibleTaskAssigneesForTask',
      'getSchoolById',
    ]),
    ...mapState('app', [
      'isOffline',
      'observationSettings',
      'organizationSettings',
      'schoolLocationsById',
      'user',
      'users',
    ]),
    mappedSchoolLocations() {
      const schoolLocations = this.schoolLocationsById[this.schoolId];
      if (!schoolLocations || !schoolLocations.usageMap) {
        return [];
      }
      const keys = Object.keys(schoolLocations.usageMap).filter(key => schoolLocations.usageMap[key] > 1);
      return keys.sort((a, b) => {
        return (
          schoolLocations.usageMap[b] - schoolLocations.usageMap[a]
        );
      });
    },
    observationCategoryById() {
      const map = {};
      this.observationCategories.forEach((observationCategory) => {
        map[observationCategory.id] = observationCategory;
      });
      return map;
    },
    observationCategories() {
      if (this.observationSettings) {
        return this.observationSettings.categories;
      }
      return [];
    },
    observationOptionsByCategoryId() {
      const map = {};
      this.observationCategories.forEach((category) => {
        map[category.id] = this.observationItemsByCategory[category.id]
          .map((observationItem) => ({
            text: observationItem.label,
            value: observationItem.id,
          }))
          .sort((a, b) => {
            if (a.text === 'Other' && b.text === 'Other') return 0;
            if (a.text === 'Other') return 1;
            if (b.text === 'Other') return -1;
            return a.text.localeCompare(b.text);
          });
      });
      return map;
    },
    observationOptionsWithCategories() {
      const options = [];
      this.observationItems.forEach((observationItem) => {
        observationItem.categories.forEach((categoryId) => {
          options.push({
            text: `${observationItem.label} (${this.observationCategoryById[categoryId].label})`,
            value: `${categoryId}__${observationItem.id}`,
          });
        });
      });
      return options.sort((a, b) => {
        if (a.text === 'Other' && b.text === 'Other') return 0;
        if (a.text === 'Other') return 1;
        if (b.text === 'Other') return -1;
        return a.text.localeCompare(b.text);
      });
    },
    observationItems() {
      if (this.observationSettings) {
        return this.observationSettings.items;
      }
      return [];
    },
    observationItemById() {
      const map = {};
      this.observationItems.forEach((observationItem) => {
        map[observationItem.id] = observationItem;
      });
      return map;
    },
    observationItemsByCategory() {
      const map = {};
      if (!this.observationSettings) {
        return map;
      }
      this.observationSettings.items.forEach((observationItem) => {
        observationItem.categories.forEach((categoryId) => {
          if (!map[categoryId]) {
            map[categoryId] = [];
          }
          map[categoryId].push(observationItem);
        });
      });
      return map;
    },
    preview () {
      const observation = this.editingObservation
      if (
        !(
          observation.observationCategoryId
        ) ||
        !observation.observationItemId
      ) {
        return '';
      }
      let preview = '"';
      const category =
        this.observationCategoryById[
          observation.observationCategoryId
        ];
      const item = this.observationItemById[observation.observationItemId];
      if (observation.observationType === 'concern') {
        preview += 'I have a concern relating to ';
      } else if (observation.observationType === 'praise') {
        preview += 'I want to give praise relating to ';
      } else {
        preview += 'I want to make an observation relating to ';
      }
      preview += category.label + ' (' + item.label + ')';
      if (observation.comment) {
        preview += '. I noticed the following';
        if (observation.location) {
          preview += ' in (the) ' + observation.location;
        }
        preview += ': ' + observation.comment;
      } else if (observation.location) {
        preview += ' in (the) ' + observation.location;
      }
      return preview + '"';
    },
  },
  methods: {
    close() {
      this.$emit('input', false)
      this.editingObservation = null
    },
    async save() {
      if (this.isSubmitting) {
        return;
      }

      this.$refs.locationCombobx.blur();
      await this.$nextTick();
      await this.$refs.observationForm.validate();
      if (!this.isObservationFormValid) {
        return;
      }

      this.isSubmitting = true;

      if (this.editingObservation.pictureFiles) {
        const newPictures = await this.uploadPictures(this.editingObservation);
        this.editingObservation.imagePaths =
        [
          ...(this.editingObservation.imagePaths || []),
          ...newPictures,
        ]
      }
      delete this.editingObservation.pictureFiles;

      this.$emit('save', this.editingObservation);

      this.$emit('input', false)
      this.isSubmitting = false;
    },
    async uploadPictures() {
      if (!this.editingObservation.pictureFiles) {
        return [];
      }

      const imagePaths = [];

      this.editingObservation.pictureFiles.forEach((file) => {
        const storageRef = ImageUploadService.beginFileUpload(`walks/${this.walkId}/${uuid()}`, file);
        imagePaths.push(storageRef.fullPath);
      });


      return imagePaths;
    },
    setObservationItemId(observationItem) {
      const observationItemId = observationItem.value;
      if (observationItemId && observationItemId.includes('__')) {
        const parts = observationItemId.split('__');
        this.editingObservation.observationCategoryId = parts[0];
        this.editingObservation.observationItemId = parts[1];
        this.$forceUpdate();
      } else {
        this.editingObservation.observationItemId = observationItemId;
        this.$forceUpdate();
      }
    },
    async setImageUrls () {
      this.imageUrls = [];
      if (this.editingObservation.imagePaths && this.editingObservation.imagePaths.length) {
        const imageUrls = await Promise.all(this.editingObservation.imagePaths.map(async (imagePath) => {
          try {
            const imageRef = ref(this.storage, imagePath);
            return await getDownloadURL(imageRef);
          } catch (e) {
            console.error(e);
            return null;
          }
        }))
        this.imageUrls = imageUrls.filter(url => url);
      }
    },
  },
  watch: {
    value: {
      handler () {
        if (this.value) {
          this.editingObservation = JSON.parse(JSON.stringify(this.initialObservation));
          if (this.initialObservationCategoryId) {
            this.editingObservation.observationCategoryId = this.initialObservationCategoryId;
          }
          if (this.isEditing) {
            this.setImageUrls();
          
          }
        }
      },
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~vuetify/src/styles/settings/_variables";
@media #{map-get($display-breakpoints, 'sm-and-down')} {
  ::v-deep .combobox-container {
    position: relative;
    .v-menu__content {
      top: 40px !important;
    }
  }
}
</style>
