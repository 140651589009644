import ViewWalks from '@/pages/walks/ViewWalks.vue'
import EditWalk from '@/pages/walks/EditWalk.vue'
import ReviewWalk from '@/pages/walks/ReviewWalk.vue'

export default [
  {
    path: '/GSBA',
    name: 'GSBA',
    component: () => import(/* webpackChunkName: "gs3" */ '@/pages/auth/ConferenceRegistrationPage.vue'),
    meta: {
      layout: 'auth',
      authNotRequired: true,
    },
  },
  {
    path: '/auth/signin',
    name: 'auth-signin',
    component: () => import(/* webpackChunkName: "auth-signin" */ '@/pages/auth/SigninPage.vue'),
    meta: {
      layout: 'auth',
      authNotRequired: true,
    },
  },
  {
    path: '/auth/set-password',
    name: 'auth-set-password',
    component: () => import(/* webpackChunkName: "auth-set-password" */ '@/pages/auth/SetPasswordPage.vue'),
    meta: {
      layout: 'auth',
      authNotRequired: true,
    },
  },
  {
    path: '/auth/verify-email',
    name: 'auth-verify-email',
    component: () => import(/* webpackChunkName: "auth-verify-email" */ '@/pages/auth/VerifyEmailPage.vue'),
    meta: {
      layout: 'auth',
      authNotRequired: true,
    },
  },
  {
    path: '/auth/forgot-password',
    name: 'auth-forgot',
    component: () => import(/* webpackChunkName: "auth-forgot" */ '@/pages/auth/ForgotPage.vue'),
    meta: {
      layout: 'auth',
      authNotRequired: true,
    },
  },
  {
    path: '/auth/sign-in-link',
    name: 'auth-sign-in-link',
    component: () => import(/* webpackChunkName: "auth-sign-in-link" */ '@/pages/auth/SignInLinkPage.vue'),
    meta: {
      layout: 'auth',
      authNotRequired: true,
    },
  },
  {
    path: '/auth/handle-sign-in-link',
    name: 'auth-handle-sign-in-link',
    component: () => import(/* webpackChunkName: "auth-handle-sign-in-link" */ '@/pages/auth/HandleSignInLinkPage.vue'),
    meta: {
      layout: 'auth',
      authNotRequired: true,
    },
  },
  {
    path: '/auth/setup',
    name: 'auth-setup',
    component: () => import(/* webpackChunkName: "auth-forgot" */ '@/pages/auth/SetupPage.vue'),
    meta: {
      layout: 'auth',
      authNotRequired: true,
    },
  },
  {
    path: '/auth/reset-password',
    name: 'auth-reset',
    component: () => import(/* webpackChunkName: "auth-reset" */ '@/pages/auth/ResetPage.vue'),
    meta: {
      layout: 'auth',
      authNotRequired: true,
    },
  },
  {
    path: '/auth/revert-mfa',
    name: 'auth-revert-mfa',
    component: () => import(/* webpackChunkName: "auth-revert-mfa" */ '@/pages/auth/RevertSecondFactorAdditionPage.vue'),
    meta: {
      layout: 'auth',
      authNotRequired: true,
    },
  },
  {
    path: '/error/not-found',
    name: 'error-not-found',
    component: () => import(/* webpackChunkName: "error-not-found" */ '@/pages/error/NotFoundPage.vue'),
    meta: {
      layout: 'error',
      authNotRequired: true,
    },
  },
  {
    path: '/error/unexpected',
    name: 'error-unexpected',
    component: () => import(/* webpackChunkName: "error-unexpected" */ '@/pages/error/UnexpectedPage.vue'),
    meta: {
      layout: 'error',
      authNotRequired: true,
    },
  },
  {
    path: '/utility/maintenance',
    name: 'utility-maintenance',
    component: () => import(/* webpackChunkName: "utility-maintenance" */ '@/pages/utility/MaintenancePage.vue'),
    meta: {
      layout: 'auth',
      authNotRequired: true,
    },
  },
  {
    path: '/utility/coming-soon',
    name: 'utility-soon',
    component: () => import(/* webpackChunkName: "utility-soon" */ '@/pages/utility/SoonPage.vue'),
    meta: {
      layout: 'auth',
    },
  },
  {
    path: '/faq',
    name: 'faq',
    component: () => import(/* webpackChunkName: "faq" */ '@/pages/utility/HelpPage.vue'),
  },
  {
    path: '/support',
    name: 'support',
    component: () => import(/* webpackChunkName: "support" */ '@/pages/support/SupportPage.vue'),
    meta: {
      authNotRequired: true,
    },
  },
  {
    path: '/walks',
    name: 'walks-view',
    component: ViewWalks,
  },
  {
    path: '/walks/walk/:walkId/inprogress',
    name: 'walk-edit',
    component: EditWalk,
  },
  {
    path: '/walks/walk/:walkId/review',
    name: 'walk-review',
    props: {
      isReviewing: true,
    },
    component: ReviewWalk,
  },
  {
    path: '/walks/walk/:walkId/complete',
    name: 'walk-complete',
    props: {
      isReviewing: false,
    },
    component: ReviewWalk,
  },
  {
    path: '/insights',
    name: 'walks-insights',
    component: () => import(/* webpackChunkName: "walks-insights" */ '@/pages/insights/InsightsPage.vue'),
  },
  {
    path: '/walkSettings',
    name: 'walks-settings',
    component: () => import(/* webpackChunkName: "walks-settings" */ '@/pages/admin/WalkSettings.vue'),
  },
  {
    path: '/administrator',
    name: 'administrator',
    component: () => import(/* webpackChunkName: "administrator" */ '@/pages/administrator/AdministratorPage.vue'),
  },
  {
    path: '/admin/schooldog',
    name: 'admin-schooldog',
    component: () => import(/* webpackChunkName: "admin-schooldog" */ '@/pages/admin/DevSettings.vue'),
  },
  {
    path: '/admin/manageUsers',
    name: 'admin-manage-users',
    component: () => import(/* webpackChunkName: "admin-manage-users" */ '@/pages/admin/ManageUsers.vue'),
    meta: {
      can: 'manage users',
    },
  },
  {
    path: '/reports',
    name: 'reports',
    component: () => import(/* webpackChunkName: "reports" */ '@/pages/reports/ReportsPage.vue'),
  },
  {
    path: '/schools',
    name: 'schools',
    component: () => import(/* webpackChunkName: "schools" */ '@/pages/school/SchoolsPage.vue'),
  },
  {
    path: '/schools/:schoolId',
    name: 'school-details',
    component: () => import(/* webpackChunkName: "schools" */ '@/pages/school/SchoolDetails.vue'),
  },
  {
    path: '/cycles',
    name: 'cycles',
    component: () => import(/* webpackChunkName: "cycles" */ '@/pages/cycle/CyclesPage.vue'),
  },
  {
    path: '/cycles/:cycleId',
    name: 'cycle-details',
    component: () => import(/* webpackChunkName: "cycle-details" */ '@/pages/cycle/CycleDetails.vue'),
  },
  {
    path: '/tasks',
    name: 'tasks',
    component: () => import(/* webpackChunkName: "tasks" */ '@/pages/tasks/TaskListPage.vue'),
  },
  {
    path: '/tasks/task/:taskId',
    name: 'task',
    component: () => import(/* webpackChunkName: "task" */ '@/pages/tasks/TaskPage.vue'),
  },
  {
    path: '/resources/links',
    name: 'resources-links',
    component: () => import(/* webpackChunkName: "cycles" */ '@/pages/resources/ResourceLinksPage.vue'),
  },
  {
    path: '/privacy',
    name: 'privacy',
    component: () => import(/* webpackChunkName: "privacy" */ '@/pages/legal/PrivacyPage.vue'),
    meta: {
      authNotRequired: true,
    },
  },
  {
    path: '/terms',
    name: 'terms',
    component: () => import(/* webpackChunkName: "tou" */ '@/pages/legal/TouPage.vue'),
    meta: {
      authNotRequired: true,
    },
  },
];
