import { signOut } from 'firebase/auth';

import AppSettings from './../../models/AppSettings';
import Cycle from './../../models/Cycle';
import Settings from './../../models/Settings';
import Task from './../../models/Task';
import User from './../../models/User';
import Walk from './../../models/Walk';
import WalkSettings from './../../models/WalkSettings';
import SchoolInfo from '../../models/SchoolInfo';
import SchoolLocations from '../../models/SchoolLocations';
import { appConfig } from '../../app-config';

const showToast = ({ state, commit }, message) => {
  if (state.toast.show) commit('hideToast')

  setTimeout(() => {
    commit('showToast', {
      color: 'black',
      message,
      timeout: 3000,
    })
  })
}

const showError = ({ state, commit }, { message = 'Failed!', error }) => {
  if (state.toast.show) commit('hideToast')

  setTimeout(() => {
    commit('showToast', {
      color: 'error',
      message: message + ' ' + error.message,
      timeout: 10000,
    })
  })
}

const showSuccess = ({ state, commit }, message) => {
  if (state.toast.show) commit('hideToast')

  setTimeout(() => {
    commit('showToast', {
      color: 'success',
      message,
      timeout: 3000,
    })
  })
}

const setUserFromFirebaseUser = async ({ commit, dispatch, state }) => {
  if (state.auth.currentUser && state.auth.currentUser.uid) {
    const inactivityTimestamp = Date.now();
    localStorage.setItem('lastActivity', inactivityTimestamp.toString());
    if (!state.isOffline) {
      // await state.auth.currentUser.getIdToken(true)
    }

    if (!state.auth.currentUser || !state.auth.currentUser.uid) {
      // It's possible that refreshing the id token above can temporarily set the currentUser to null.
      // If that happens, we should rely on the onAuthStateChanged listener to re-trigger this action.

      /// TODO: Replace log with a return to rely on the listener
      console.log('refresh token not valid')
      // return;
    }
    // date in timestamp format
    const user = await User.getById(state.auth.currentUser.uid);
    user.startListening(async (user) => {
      if (user.autoSignOutData) {
        const autoSignOutKey = user.autoSignOutData.key;
        const timestamp = user.autoSignOutData.timestamp;
        const key = localStorage.getItem(`user-${user.id}-asok-${autoSignOutKey}`);
        const signInTimestamp = parseInt(localStorage.getItem('signInTimestamp') || 0);

        if (!key && signInTimestamp < timestamp) {
          localStorage.setItem(`user-${user.id}-asok-${autoSignOutKey}`, '1');
          dispatch('logOut');
        }
      }

      if (!state.isOffline) {
        await state.auth.currentUser.getIdToken(true)
      }
    })
    if (user) {
      commit('setUser', user);
      dispatch('syncWalks');
      dispatch('syncTasks');
      dispatch('syncSchoolInfos')
      dispatch('syncSchoolLocations')
      dispatch('initialize');
    } else {
      commit('setUser', null);
    }
  } else {
    commit('setUser', null);
  }
  if (!state.isAuthInitialized) {
    commit('setIsAuthInitialized', true);
  }
};

async function clearIndexedDb() {
  // Check if the browser supports indexedDB.databases()
  if (!indexedDB.databases) {
    console.error('The browser does not support indexedDB.databases()');
    return;
  }

  try {
    // Get all IndexedDB databases for the current origin
    const databases = await indexedDB.databases();

    for (const dbInfo of databases) {
      const dbName = dbInfo.name;

      // Delete each database
      const deleteRequest = indexedDB.deleteDatabase(dbName);

      deleteRequest.onerror = function (event) {
        console.error(`Error deleting database '${dbName}':`, event.target.error);
      };

      deleteRequest.onblocked = function () {
        console.error(`Deletion of database '${dbName}' is blocked.`);
      };
    }
  } catch (error) {
    console.error('Error retrieving databases:', error);
  }
}

async function logOut({ dispatch, state }) {
  try {
    await signOut(state.auth);

    /// TODO: Uncomment after clearing this with users
    // clearIndexedDb()
  } catch (e) {
    console.warn('Error signing out', e);
  } finally {
    // However, we must ensure sensitive data is wiped if attempting to log out.
    dispatch('resetState');
  }
}

function resetState({ commit, state }) {
  if (state.globalAppSettings && state.globalAppSettings.stopListening) {
    state.globalAppSettings.stopListening();
  }
  commit('setGlobalAppSettings', new AppSettings());
  commit('setUser', null);
  commit('setUsers', []);
  commit('setOrganizationSettings', new Settings());
  commit('setObservationSettings', new Settings());
  commit('setWalkSettings', new Settings());
  commit('setWalks', []);
  commit('setTasks', []);
  commit('setCycles', []);
  commit('setSchoolInfos', []);
  commit('setIsAuthInitialized', false);
  commit('setHaveWalksLoaded', false);
  commit('setHaveTasksLoaded', false);
  commit('setLastTimeWalksUpdated', null);
  commit('setLastTimeTasksUpdated', null);

  localStorage.removeItem('lastActivity');
  localStorage.removeItem('signInTimestamp');
}

async function loadAppSettings({ commit, dispatch }) {
  const appSettings = await AppSettings.getById('global');
  appSettings.startListening(() => {
    commit('setGlobalAppSettings', appSettings);
  });
  commit('setGlobalAppSettings', appSettings);
}

async function initialize({ commit, state, dispatch }) {
  // Initiating all asynchronous operations concurrently
  const promiseMap = {
    organizationSettings: Settings.getById('organization', true),
    users: User.getAll(),
    appSettings: dispatch('loadAppSettings'),
  };
  const user = state.user;
  if (user.can('view cycles') || user.can('manage cycles')) {
    promiseMap.walkSettings = WalkSettings.getById('walk', true);
  }
  if (user.can('view cycles') || user.can('view observation leaderboard')) {
    promiseMap.cycles = Cycle.getAll();
  }
  promiseMap.observationSettings = Settings.getById('observation', true);

  // Await all promises and store the resolved values
  const resolvedValues = await Promise.all(Object.values(promiseMap));

  // Create an array of the keys to map resolved values correctly
  const keys = Object.keys(promiseMap);

  // Create an object to map keys to their resolved values
  const results = {};
  keys.forEach((key, index) => {
    results[key] = resolvedValues[index];
  });

  // Once all promises are resolved, commit the results
  commit('setUsers', results.users || []);
  commit('setOrganizationSettings', results.organizationSettings || {});
  commit('setObservationSettings', results.observationSettings || {});
  commit('setWalkSettings', results.walkSettings || {});
  commit('setCycles', results.cycles || []);
  commit('setHaveCyclesLoaded', true);
}

async function syncWalks({ commit, state }) {
  // Need to wait to load walks until we know which user to load from
  if (!state.user) {
    return
  }

  const user = state.user

  const timeLastUpdated = state.lastTimeWalksUpdated
  commit('setLastTimeWalksUpdated', new Date())

  commit('setHaveWalksLoaded', false)

  const walkQueryPromises = [];

  if (!state.user.isSchoolDogStaff) {
    const hasFullManageAccess = user.can('manage walks');
    let partialFullAccessSchoolIds = [];
    if (!hasFullManageAccess && user.canAtAnySchool('manage walks')) {
      partialFullAccessSchoolIds = [
        ...new Set([
          ...(user.permissions['manage walks']?.forSchools || []),
        ]),
      ];
      partialFullAccessSchoolIds.forEach((schoolId) => {
        walkQueryPromises.push(Walk.getAllWhere([
          [
            'schoolId',
            '==',
            schoolId,
          ],
          [
            'walkType',
            '==',
            'school',
          ],
        ]))
      })
    } else if (hasFullManageAccess) {
      walkQueryPromises.push(Walk.getAllWhere([
        [
          'walkType',
          '==',
          'school',
        ],
      ]))
    }

    if (user.can('manage other walks')) {
      walkQueryPromises.push(Walk.getAllWhere([
        [
          'walkType',
          '==',
          'partner',
        ],
      ]))
      walkQueryPromises.push(Walk.getAllWhere([
        [
          'walkType',
          '==',
          'district',
        ],
      ]))
    }

    const hasCollaborateAccess = user.can('collaborate on walks') || user.can('create walks');
    let partialAccessSchoolIds = [];
    if (!hasCollaborateAccess && (user.canAtAnySchool('collaborate on walks') || user.canAtAnySchool('create walks'))) {
      partialAccessSchoolIds = [
        ...new Set([
          ...(user.permissions['collaborate on walks']?.forSchools || []),
          ...(user.permissions['create walks']?.forSchools || []),
        ]),
      ];
      partialAccessSchoolIds.forEach((schoolId) => {
        walkQueryPromises.push(Walk.getAllWhere([
          [
            'collaborators',
            'array-contains',
            state.user.id,
          ],
          [
            'schoolId',
            '==',
            schoolId,
          ],
        ]))
        walkQueryPromises.push(Walk.getAllWhere([
          [
            'leaderUserId',
            '==',
            state.user.id,
          ],
          [
            'schoolId',
            '==',
            schoolId,
          ],
        ]))
      })
    } else if (hasCollaborateAccess) {
      walkQueryPromises.push(Walk.getAllWhere([
        [
          'collaborators',
          'array-contains',
          state.user.id,
        ],
      ]))
      walkQueryPromises.push(Walk.getAllWhere([
        [
          'leaderUserId',
          '==',
          state.user.id,
        ],
      ]))
    }

    walkQueryPromises.push(Walk.getAllWhere([
      [
        'awaitingReviewBy',
        '==',
        state.user.id,
      ],
    ]))

    const hasViewCompletedAccess = user.can('view completed walks');
    let partialViewCompletedSchoolIds = [];
    if (!hasViewCompletedAccess && user.canAtAnySchool('view completed walks')) {
      partialViewCompletedSchoolIds = [
        ...new Set([
          ...(user.permissions['view completed walks']?.forSchools || []),
        ]),
      ];
      partialViewCompletedSchoolIds.forEach((schoolId) => {
        walkQueryPromises.push(Walk.getAllWhere([
          [
            'schoolId',
            '==',
            schoolId,
          ],
          [
            'status',
            '==',
            'complete',
          ],
        ]))
      })

    } else if (hasViewCompletedAccess) {
      walkQueryPromises.push(Walk.getAllWhere([
        [
          'status',
          '==',
          'complete',
        ],
      ]))
    }
  } else {
    walkQueryPromises.push(Walk.getAll())
  }

  const results = await Promise.all(walkQueryPromises)
  const allWalks = results.flat();

  if (allWalks.length) {
    // The mutation will filter duplicates keeping the ones at the end of the array
    const walks = [
      ...state.walks,
      ...allWalks,
    ]
    commit('setWalks', walks);
  }

  commit('setHaveWalksLoaded', true)
}

async function syncTasks({ commit, state }) {
  // Need to wait to load tasks until we know which user to load from
  if (!state.user) {
    return
  }

  const timeLastUpdated = state.timeLastUpdated
  commit('setLastTimeTasksUpdated', new Date())

  commit('setHaveTasksLoaded', false)

  let tasks = [
    ...state.tasks,
  ]

  for (let i = 0; i < 3; i++) {
    const field = [
      'assignedToUserId',
      'createdByUserId',
      'delegatedByUserId',
    ][i]

    const conditions = [
      [
        field,
        '==',
        state.user.id,
      ],
    ]

    // if (timeLastUpdated) {
    //   conditions.push([
    //     'timeUpdated',
    //     '>=',
    //     timeLastUpdated,
    //   ])
    // }
    const recentlyUpdatedTasks = await Task.getAllWhere(conditions)

    if (recentlyUpdatedTasks.length) {
      // The mutation will filter duplicates keeping the ones at the end of the array
      tasks = [
        ...tasks,
        ...recentlyUpdatedTasks,
      ]
    }
  }

  commit('setTasks', tasks);
  commit('setHaveTasksLoaded', true)
}

async function syncSchoolInfos({ commit, state }) {
  const conditions = []
  commit('setSchoolInfos', await SchoolInfo.getAllWhere(conditions));
}

async function syncSchoolLocations({ commit, state }) {
  const user = state.user;
  if (user.can('collaborate on walks') || user.can('create walks')) {
    commit('setSchoolLocations', await SchoolLocations.getAll());
  } else if (user.canAtAnySchool('collaborate on walks') || user.canAtAnySchool('create walks')) {
    const validSchoolIds = [
      ...new Set([
        ...(user.permissions['collaborate on walks']?.forSchools || []),
        ...(user.permissions['create walks']?.forSchools || []),
      ]),
    ];
    const batchSize = 10;
    const promises = [];
    for (let i = 0; i < validSchoolIds.length; i += batchSize) {
      const batch = validSchoolIds.slice(i, i + batchSize);
      promises.push(SchoolLocations.getAllWhere([
        [
          '__documentId__',
          'in',
          batch,
        ],
      ]))
    }
    const results = await Promise.all(promises);
    const schoolLocations = results.flat();
    commit('setSchoolLocations', schoolLocations);
  } else {
    commit('setSchoolLocations', []);
  }

}

function createUserFromObject(_, { id, data }) {
  return new User(id, data);
}


export default {
  createUserFromObject,
  initialize,
  loadAppSettings,
  logOut,
  resetState,
  showToast,
  showError,
  showSuccess,
  setUserFromFirebaseUser,
  syncSchoolInfos,
  syncSchoolLocations,
  syncTasks,
  syncWalks,
}
